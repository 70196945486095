import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FlagHu from '../../../images/flag-hu.png';
import FlagEn from '../../../images/flag-en.png';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    languageSelectorMenu: {
      position: 'absolute',
      right: 65,
      top: 20,
      padding: '8px',
      transition: 'left .3s',
      boxShadow: '0px 1px 8px rgba(178, 167, 125, 0.6)',
      borderRadius: '5px',
    },
    languageImage: {
        maxHeight: '20px',
        margin: '0 5px',
        cursor: 'pointer',
    }
}));

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
  
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    };
  
    return (
      <Paper className={classes.languageSelectorMenu}>
        <img className={classes.languageImage} onClick={() => changeLanguage('hu')} src={FlagHu} alt="FlagHu"/>
        <img className={classes.languageImage} onClick={() => changeLanguage('en')} src={FlagEn} alt="FlagEn"/>
      </Paper>
    );
};