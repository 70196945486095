import React, { FC } from 'react';
import {
  Avatar,
  Dialog,
  DialogContent, List,
  ListItem,
  ListItemAvatar, ListItemSecondaryAction, ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close, DescriptionOutlined } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../data-management/store';
import { UploadedFile } from '../../hooks/useUniqueFileUploader';
import DeleteIcon from '@material-ui/icons/Delete';
import { uniqueProductsActions } from '../../data-management/uniqueProductsSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type UniqueProductFileShowModalProps = {
  open: boolean
  onClose: () => void
  productIndex: number
}

export const UniqueProductFileShowModal: FC<UniqueProductFileShowModalProps> = (
  {
    open,
    onClose,
    productIndex
  },
) => {
  const classes = useStyles();

  const uploadedFiles = useSelector<RootState, UploadedFile[] | undefined>(
    state => state.uniqueProducts.uniqueProducts[productIndex]?.files
  ) ?? []

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onModalClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          {t('unique_product_file_show_modal_custom_products')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List
          subheader={<ListSubheader>{t('unique_product_file_show_modal_list_of_files')}</ListSubheader>}
        >
          {
            uploadedFiles.map((
              {
                title,
                mime
              }, index
            ) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={title}
                  secondary={mime}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      dispatch(uniqueProductsActions.removeProductFile({
                        index: productIndex,
                        fileIndex: index
                      }))
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onModalClose}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          {t('unique_product_file_show_modal_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
