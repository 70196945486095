import React, { FC, Fragment, useEffect, useState } from 'react';
import { CustomAppBar } from '../Components/AppBar/CustomAppBar';
import { Header } from '../Components/Header/Header';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { authActions, AuthState } from '../data-management/authSlice';
import { flashActions, FlashState } from '../data-management/flashSlice';
import { HideOnPrint } from '../Components/HideOnPrint';
import { Redirect } from 'react-router-dom';
import { useTokenRefresh } from '../hooks/useTokenRefresh';
import { useToken } from '../hooks/useToken';
import { RootState } from '../data-management/store';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  menu: {
    transition: 'all .3s',
  },
  margin: {
    marginLeft: '250px',
    width: 'calc(100% - 250px)',
    '@media print': {
      marginLeft: 0,
    },
  },
}));

toast.configure();


export const MainLayout: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const token = useToken();
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState<string | boolean>(false);
  const auth = useSelector<RootState, AuthState>(state => state.auth);
  const flash = useSelector<RootState, FlashState['messages']>(state => state.flash.messages);
  const flashErrors = useSelector<RootState, FlashState['errorMessages']>(state => state.flash.errorMessages);

  const onSetUser = async () => {
    if (token !== undefined && auth.isLoggedIn === false) {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(authActions.me(res.data));
          setRedirect(false);
        })
        .catch((error) => {
          let errorMsg;
          if (error.response.status === 401) {
            errorMsg = t('main_layout_wrong_credentials');
          } else {
            errorMsg = error.response.data.error;
          }
          setRedirect('/');
          console.log(errorMsg);
        });
      ;
    }
  };

  useEffect(() => {
    if (flashErrors.length > 0) {
      flashErrors.forEach(function(message, key) {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          // @ts-ignore: Unreachable code error
          theme: "colored",
          onOpen: () => {
            dispatch(flashActions.deleteErrorMessage(key));
          },
        });
      });
    }
    if (flash.length > 0) {
      flash.forEach(function(message, key) {
        toast(message, {
          position: toast.POSITION.TOP_RIGHT,
          onOpen: () => {
            dispatch(flashActions.deleteFlashMessage(key));
          },
        });
      });
    }
  }, [flash, flashErrors]);

  const refreshToken = useTokenRefresh();

  useEffect(() => {
    let timeout = setTimeout(() => {
      refreshToken(() => {
        setRedirect('/');
      });
    }, 2 * 60 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [refreshToken]);

  useEffect(() => {
    onSetUser();
  }, []);

  const onToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  if (redirect)
    return (
      <Redirect
        to={redirect !== true ? redirect : '/'}
      />
    );

  return (
    <Fragment>
      <HideOnPrint component={Box}>
        <Header open={openMenu} />
        <CustomAppBar title={t('main_layout_order_place')} toggleMenu={onToggleMenu} open={openMenu} />
      </HideOnPrint>
      <div className={clsx(classes.menu, openMenu ? classes.margin : '')}>{children}</div>
    </Fragment>
  );
};

