import React, { FC, useState } from 'react';
import { Dialog, DialogContent, FormControl, makeStyles, RadioGroup, Typography, FormControlLabel, Radio, TextField, Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { flashActions } from '../../data-management/flashSlice';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type AskPricePrintModalProps = {
  open: boolean
  onClose: () => void
  url: string
}

export const AskPricePrintModal: FC<AskPricePrintModalProps> = React.memo((
  {
    open,
    onClose,
    url,
  },
) => {

  const classes = useStyles()
  const dispatch = useDispatch();
  const browserHistory = useHistory();
  const { t } = useTranslation();

  const [error, setError] = useState<string | undefined>(undefined)
  const [selectedPriceOption, setSelectedPriceOption] = useState<string | undefined>(undefined)
  const [percentage, setPercentage] = useState<string | undefined>(undefined)

  const onModalClose = () => {
    onClose();
  }

  const onConfirm = () => {
    if(selectedPriceOption == "price_gap"){
      if( ! (percentage && parseInt(percentage) && parseInt(percentage) > 0 && parseFloat(percentage) % 1 == 0)){
        dispatch(flashActions.addFlashMessage(t('ask_price_print_modal_percent_must_be_positive')));
        return;
      }
    }
    
    window.open(`${url}?price_option=${selectedPriceOption}&percentage=${percentage ?? 0}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
      onClose={onModalClose}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          {t('ask_price_print_modal_title_text')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          error ?
            <Alert severity={'error'}>
              {error}
            </Alert> : <></>
        }
        <Typography>
        {t('ask_price_print_modal_choose_from_list')}
        </Typography>
        <Box>
        <FormControl component="fieldset">
          <RadioGroup
            name="price_option"
            value={selectedPriceOption ?? ""}
            onChange={(event) => {
              setSelectedPriceOption(event.target.value);
            }}
          >
          <FormControlLabel
            key="price_1"
            value="list_price"
            control={<Radio />}
            label={<><b>{t('ask_price_print_modal_option_list')}</b></>
            }
          />
          <FormControlLabel
            key="price_2"
            value="my_price"
            control={<Radio />}
            label={<><b>{t('ask_price_print_modal_option_my_price')}</b></>
            }
          />
          <FormControlLabel
            key="price_3"
            value="price_gap"
            control={<Radio />}
            label={<><b>{t('ask_price_print_modal_option_price_gap')}</b> - {t('ask_price_print_modal_option_for_my_price')}</>
            }
          />
          </RadioGroup>
        </FormControl>
        </Box>
        <Box mb={2} mt={4}>
        <Typography>
          {t('ask_price_print_modal_option_price_gap_percentage')} (%)
        </Typography>
        <TextField
          type="number"
          name="percentage"
          value={percentage}
          onChange={(event) => {
            setPercentage(event.target.value);
          }}
          variant="standard"
          disabled={selectedPriceOption != "price_gap"}
        />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onConfirm}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          {t('ask_price_print_modal_submit_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
