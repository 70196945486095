import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OrderInfo from './OrderInfo';
import { OrderItem } from './OrderItem';
import { useCategoryId } from '../../hooks/useCategoryId';
import { useSelector } from 'react-redux';
import { RootState } from '../../data-management/store';
import { OrderItem as OrderItemType } from '../../data-management/orderSlice';
import { SettingsType, useSettingsGet } from '../../hooks/useSettingsGet';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    marginTop: 20,
    '@media print': {
      boxShadow: 'none',
    },
  },
  title: {
    fontWeight: 300,
    paddingBottom: theme.spacing(5),
    paddingLeft: '24px',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
  },
  itemTitle: {
    flexGrow: 1,
    width: 'calc(8.3% - 15px)',
    marginRight: 15,
  },
  '@global': {
    '.orderlist .orderitem:nth-of-type(odd)': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
    'div.item:nth-of-type(odd)': {
      backgroundColor: 'rgba(196, 196, 196, 0.1);',
    },
  },
}));

export const OrderList: FC = () => {
  const { t } = useTranslation();
  const settingsGetApi = useSettingsGet();
  const classes = useStyles();
  const { fields } = useCategoryId();
  const orders = useSelector<RootState, OrderItemType[]>(state => state.order.orders);
  const loading = useSelector<RootState, boolean>(state => state.order.loading);

  const [canEditOrderItem, setCanEditOrderItem] = useState<any>(undefined);
  
  useEffect(() => {
    settingsGetApi().then((settings) => {
      if(settings.can_edit_order_item){
        setCanEditOrderItem(settings.can_edit_order_item.value == "1" ? 1 : 0);
      }
    });
  }, [canEditOrderItem]);

  return (
    <>
      <div className={`orderlist ${classes.root}`}>
        <Container className={classes.container} maxWidth={false}>
          <Typography className={classes.title} variant="h4">
            {t('order_list_title_text')}
          </Typography>
          {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> : 
          <TableContainer component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  {fields.map((field, index) => (
                    <TableCell key={index} align="right">{t(field.label)}</TableCell>
                  ))}
                  <TableCell align="right">{t('order_list_column_size')}</TableCell>
                  <TableCell align="right">{t('order_list_column_time')}</TableCell>
                  <TableCell align="right">{t('order_list_column_extra')}</TableCell>
                  <TableCell align="right">{t('order_list_column_net')}</TableCell>
                  <TableCell align="center">{t('order_list_column_comment')}</TableCell>
                  <TableCell align="center">{t('order_list_column_actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  orders?.map((order, index) =>
                    <OrderItem
                      key={index}
                      item={order}
                      index={index}
                      can_edit={canEditOrderItem}
                    />,
                  )
                }
              </TableBody>
            </Table>
          </TableContainer> }
        </Container>
        <OrderInfo />
      </div>
    </>
  );
};

