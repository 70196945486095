import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

export type SizeChartProps = {
  flat?: boolean
  min_width: number
  max_width: number
  min_height?: number
  max_height?: number
  type?: string
}

const useStyles = makeStyles((theme) => ({
  header: {
    textDecoration: 'underline',
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    color: '#B2A77D'
  },
  name: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate(-180deg)',
    fontWeight: 700,
    maxHeight: 80,
    textAlign: 'center',
  },
  hidden: {
    visibility: 'hidden',
  },
  none: {
    display: 'none',
    width: '1px'
  },
  minMax: {
    fontFamily: "Avenir LT Std",
    fontWeight: 350,
    fontSize: 14,
    lineHeight: '16.8px',
    color: '#535353',
    paddingRight: 9
  },
  heightWidth: {
    fontFamily: "Avenir LT Std",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19.2px',
    color: '#535353',
  },
  size: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    color: '#535353'
  }
}));

export const SizeChart: FC<SizeChartProps> = (
  {
    flat,
    min_height,
    min_width,
    max_height,
    max_width,
    type,
  },
) => {

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sm={flat ? 12 : 6}
      md={flat ? 12 : 4}
      lg={flat ? 12 : 2}
      container
      justify={'center'}
      spacing={1}
    >
      {
        !flat ?
          <>
            {
              !type ?
                <Grid
                  item
                  container
                  xs={12}
                  justify={'center'}
                  direction={'column'}
                  spacing={1}
                >
                  <Grid item>
                    <Typography className={classes.hidden}>
                      .
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.heightWidth}>
                      {t('size_chart_height')} ( mm ):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.heightWidth}>
                      {t('size_chart_width')} ( mm ):
                    </Typography>
                  </Grid>
                </Grid>
                :
                <Grid
                  item
                  container
                  xs={12}
                  justify={'center'}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography align={'center'} className={classes.header}>
                      {t(type)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display={'inline'} className={classes.minMax} >min</Typography>
                    <Typography
                      align={'center'}
                      display={'inline'}
                      className={classes.size}
                    >
                      {
                        min_height !== undefined ?
                          `${min_height}` : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display={'inline'} className={classes.minMax} >max</Typography>
                    <Typography
                      align={'center'}
                      display={'inline'}
                      className={classes.size}
                    >
                      {
                        max_height !== undefined ?
                          `${max_height}` : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display={'inline'} className={classes.minMax} >min</Typography>
                    <Typography
                      align={'center'}
                      display={'inline'}
                      className={classes.size}
                    >
                      {min_width}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display={'inline'} className={classes.minMax} >max</Typography>
                    <Typography
                      align={'center'}
                      display={'inline'}
                      className={classes.size}
                    >
                      {max_width}
                    </Typography>
                  </Grid>
                </Grid>
            }
          </> :
          <Grid item container xs={12} sm={8} md={7} justify={'space-around'}>
            <Grid item xs container justify={'center'} spacing={4}>
              <Grid item xs={2}>
                <Typography className={classes.header} align={'right'}>
                  Min.:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t('size_chart_height')}: <strong>{min_height ?? 0} mm</strong>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t('size_chart_width')}: <strong>{min_width} mm</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs container justify={'center'} spacing={4}>
              <Grid item xs={2}>
                <Typography className={classes.header} align={'right'}>
                  Max.:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t('size_chart_height')}: <strong>{max_height ?? 0} mm</strong>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {t('size_chart_width')}: <strong>{max_width} mm</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      }
    </Grid>
  );
};