import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { OrderHistoryItem, useOrderHistory } from '../hooks/useOrderHistory';
import { DataGrid, GridColumns, GridSortModel, GridFilterModel, GridToolbar } from '@material-ui/data-grid';
import { addDays, addMinutes, parseISO, setSeconds } from 'date-fns';
import { Visibility, Edit } from '@material-ui/icons';
import axios from 'axios';
import { useToken } from '../hooks/useToken';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { ConfirmLoadExistingOrderModal } from '../Components/Order/ConfirmLoadExistingOrderModal';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    minHeight: '90vh',
    '& .MuiDataGrid-row.Mui-even': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
  },
  paper: {
    padding: '40px 50px 52px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  filterItem: {
    flexGrow: 1,
    marginRight: theme.spacing(20),
  },
  itemFlexGrow: {
    flexGrow: 1,
    minHeight: 400,
    padding: '50px',
  },
  statusRow: {
    backgroundColor: '#DEFFBD',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  errorRow: {
    backgroundColor: '#ffbdbd',
    paddingLeft: '6px',
    paddingRight: '6px'
  }
}));

const OrderHistoryList = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const browserHistory = useHistory();

  const [filter, setFilter] = useState({
    id: '',
    date: new Date('2021-01-29T00:00:00'),
  });
  const [loading, setLoading] = useState(false);
  const [loadOrderModal, setLoadOrderModal] = useState<any | undefined>(undefined);
  const orgId = useSelectedOrganisationId();

  const [items, setItems] = useState<OrderHistoryItem[] | undefined>(undefined);

  const onHandleChange = (e: any) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'order_date',
      sort: 'desc',
    },
  ]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({items: [{}]});

  const token = useToken();

  const refreshTable = function() {
    if(orgId != -1){
      setLoading(true);
      axios.get<OrderHistoryItem[]>(`${process.env.REACT_APP_SERVER_URL}/order/get2/${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(({ data }) => {
        setItems(data);
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    refreshTable();
  }, [orgId]);

  const columns: GridColumns = [
    {
      field: 'ponumber',
      headerName: t('order_list_column_id'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.75,
    },
    {
      field: 'order_date',
      headerName: t('order_list_column_created_at'),
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.getValue(params.id, 'finalized_at');
        return typeof value === 'string' ?
          parseISO(value) : new Date();
      },
      type: 'dateTime',
    },
    {
      field: 'order_feldolgozas_date',
      headerName: t('order_list_column_work_start'),
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.getValue(params.id, 'finalized_at');
        let date = typeof value === 'string' ?
          parseISO(value) : new Date();

        const remainingMinutes = 60 - date.getMinutes();
        date = addMinutes(date, remainingMinutes)
        date = addDays(date, 1)
        date = setSeconds(date, 0)

        return date
      },
      type: 'dateTime',
    },
    {
      field: 'status',
      headerName: t('order_list_column_status'),
      flex: 0.75,
      valueGetter: (params) => {
        const value = params.value as { text?: string };
        return value ?? '';
      },
      renderCell: (params) => (
        <Typography className={params.value == "Hiba a rögzítés során" ? classes.errorRow : classes.statusRow}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'totalArea',
      headerName: t('order_list_column_size'),
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${parseFloat(params.value as string) ? parseFloat(params.value as string).toFixed(4) : 0} m²`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'totalNetPrice',
      headerName: t('order_list_column_net_price'),
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${(Math.round(params.value as number)).toLocaleString?.()} Ft`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'totalBrutPrice',
      headerName: t('order_list_column_gross_price'),
      flex: 0.5,
      type: 'number',
      valueFormatter: (params) =>
        `${(Math.round(params.value as number)).toLocaleString?.()} Ft`,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'purchase_number',
      headerName: t('order_list_column_self_identification'),
      flex: 0.5,
      type: 'string',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'show',
      headerName: '',
      sortable: false,
      resizable: false,
      editable: false,
      hideSortIcons: true,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderHeader: () => (
        <div />
      ),
      renderCell: (params) => (
        <>
        { (params.getValue(params.id, 'status') === "Parkolás" || params.getValue(params.id, 'status') === "Hiba a rögzítés során") && params.getValue(params.id, 'is_custom') != 1 ?
        <IconButton onClick={() => {
          setLoadOrderModal(params.row.id);
        }}>
          <Edit />
        </IconButton> : <></> }
        <IconButton
          href={`/order-history/details/${params.row.secure_id}`}
        >
          <Visibility />
        </IconButton>
        </>
      ),
    },
  ];

  const rows = items ?? [];

  return (
    <>
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.filterItem} variant="h4">
              {t('order_list_title_text')}
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item className={classes.itemFlexGrow}>
          { orgId === -1 ? <Box mb={3}><Alert severity="warning">{t('order_list_title_choose_company')}</Alert></Box> : <Box mb={3}><Alert severity="warning"><>{t('order_list_title_error_in_orders_1')} <Link to="/kapcsolat">{t('order_list_title_error_in_orders_2')}</Link> {t('order_list_title_error_in_orders_3')}</></Alert></Box> }
        <Paper>
          {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> :
          <div style={{ minHeight: 300, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
              sortModel={sortModel}
              onSortModelChange={({ sortModel }) => setSortModel(sortModel)}
              filterModel={filterModel}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div> }
        </Paper>
      </Grid>
    </Grid>
    <ConfirmLoadExistingOrderModal
        open={!!loadOrderModal}
        onClose={() => {
          setLoadOrderModal(undefined);
        }}
        onSuccess={() => {
          browserHistory.push(`/order/load-existing/${loadOrderModal}`);
        }}
      />
    </>
  );
};

export default OrderHistoryList;
