import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { usePrivacyGet } from '../hooks/usePrivacyGet';
import parse from 'html-react-parser';
import {
  Container,
  Box,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px 2px rgba(83, 83, 83, 0.16)',
  },
  loginBackground: {
    width: '100%',
    height: '100%',
    background: 'url(/images/authbg.png) no-repeat center center/cover',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: .5,
    zIndex: -1,
  },
}));

const PrivacyPage = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const settingsGetApi = usePrivacyGet();
  const [privacy, setPrivacy] = useState<any>(t('privacy_page_loading_state'));

  useEffect(() => {
    settingsGetApi().then((settings : any) => {
      setPrivacy(settings.privacy.value);
    });
  }, []);

  return (
    <div>
      <div className={classes.loginBackground}/>
      <Container maxWidth={'lg'} className={classes.root} component={Paper}>
        <Box className={"ql-editor"}>{parse(privacy)}</Box>
      </Container>
    </div>
  );
};

export default PrivacyPage;
