import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../images/logo-dark.png';
import { HideOnPrint } from '../Components/HideOnPrint';
import { useRouteMatch } from 'react-router-dom';
import { OrderHistoryItemExtended } from '../hooks/useOrderHistory';
import { useToken } from '../hooks/useToken';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    '@media print': {
      padding: 0,
      backgroundColor: 'white'
    }
  },
  rightSidebar: {
    padding: theme.spacing(3),
    boxShadow: '0px 2px 4px 2px rgba(83, 83, 83, 0.16)',
    '@media print': {
      position: 'absolute',
      width: 'auto',
      boxSizing: 'border-box',
      padding: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      minHeight: '100%',
      height: 'auto',
      boxShadow: 'unset'
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div.item': {
      width: '8.3%',
      textAlign: 'left',
    },
  },
  infoRow: {
    color: '#535353',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {

    },
    padding: '15px 10px',
    borderBottom: '1px solid rgba(196,196,196,0.5)',
  },
  tableCellBreakWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: 100,
  },
  subTitle: {
    fontSize: 16,
    textDecoration: 'underline',
    opacity: 0.8,
    color: '#535353',
    marginBottom: 16
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 'none',
    },
  }),
)(TableCell);

const StyledHeadTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F8F8F8',
    },
  }),
)(TableRow);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#F8F8F8',
      },
    },
  }),
)(TableRow);

const OrderDetails: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState<OrderHistoryItemExtended | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(t('order_details_loading_state'))

  const { params: { id } } = useRouteMatch<{
    id: string | undefined
  }>();

  const token = useToken();

  useEffect(() => {
    axios.get<OrderHistoryItemExtended>(`${process.env.REACT_APP_SERVER_URL}/order/details/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      setData(data);
    }).catch(e => {
      setError(t('order_details_order_does_not_exists'));
    })
  }, [id])

  const finalizedAt = typeof data?.finalized_at === 'string' ?
    format(parseISO(data.finalized_at), 'yyyy.MM.dd. HH:mm') : 'Nincs';

  let fieldNumber = 0;
  if(data && data.items && data.items[0]){
    fieldNumber = data?.items[0]?.features?.findIndex(({ code }) => code === 'MAG') ?? 0;
  }
  if (fieldNumber === -1) fieldNumber = 0;

  return (
    <div className={classes.root} id="order-history">
      {
        data ?
          <Grid container spacing={5}>
            <Grid item lg={9}>
              <Paper className={classes.rightSidebar}>
                <div className={classes.flexRow} style={{ marginBottom: 50 }}>
                  <Typography variant="body1">{t('order_details_order_number_text')} {data.ponumber}</Typography>
                  <Typography variant="body1">{t('order_details_order_submit_date')} {finalizedAt}</Typography>
                </div>
                <div
                  className={classes.flexRow}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Typography variant="h4" style={{ fontWeight: 300 }}>
                    { data.is_modified_at ? t('order_details_title_modified_order') : t('order_details_title_simple_order')}
                  </Typography>
                  <img src={Logo} alt="Nettfront" />
                </div>
                <Box mt={5} />
                <Divider />
                <Box mt={5} />
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ textDecoration: 'underline', marginBottom: 30, opacity: 0.8 }}>
                      {t('order_details_creator_data')}
                    </Typography>
                    <Typography variant="h4" gutterBottom style={{ fontWeight: 300 }}>
                      Nett Front Kft.
                    </Typography>
                    <Typography variant="body1">
                      5650 Mezőberény, Gyomai út 26. <br />
                      rendeles@nettfront.hu <br />
                      +36 66 424 102
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ textDecoration: 'underline', marginBottom: 30, opacity: 0.8 }}>
                      {t('order_details_customer_text')}
                    </Typography>
                    <Typography variant="h4" gutterBottom style={{ fontWeight: 300 }}>
                      {data.organization.name}
                    </Typography>
                    <Typography variant="body1">
                      {data.billingAddress.zip} {data.billingAddress.city} {data.billingAddress.address}
                    </Typography>
                    <Typography variant="body1">
                      {data.billingAddress.phone}
                  </Typography>
                  </Grid>
                </Grid>
                <Box mt={5} />
                <Divider />
                <Box mt={5} />
                <Typography variant="body1" style={{ textDecoration: 'underline', marginBottom: 20, opacity: 0.8 }}>
                  {t('order_details_products_data')}
                </Typography>
                {data.is_custom ? 
                  <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Table>
                    <TableHead>
                      <StyledHeadTableRow>
                        <StyledTableCell key={0}>
                          {t('order_details_table_product')}
                        </StyledTableCell>
                      </StyledHeadTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data.items.map((item, index) => {
                          return (
                            <StyledTableRow key={item.id}>
                              <StyledTableCell>{index+1}. {t('order_details_table_position')} {item.note}</StyledTableCell>
                            </StyledTableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ marginBottom: 5 }}>{t('order_details_table_self_identification')}</Typography>
                    <Typography variant="body1">
                      {data.purchase_number ?? "-"}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 5, marginTop: 20 }}>{t('order_details_table_project_description')}</Typography>
                    <Typography variant="body1">
                       {data.note}
                    </Typography>
                  </Grid>
                </Grid>
                : 
                <Table>
                  <TableHead>
                    <StyledHeadTableRow>
                      {
                        [
                          t('order_details_table_product'),
                          ...(data?.items[0]?.features
                            .filter(({ code }) => { return code == "PROD" || code == "ELZ" || code == "FENY" || code == "PANT" || code == "SZIN" })
                            .map(({description}) => t(description)) ?? []),
                          t('order_details_table_height'),
                          t('order_details_table_width'),
                          t('order_details_table_count'),
                          t('order_details_table_size'),
                          t('order_details_table_net'),
                          t('order_details_table_gross'),
                          t('order_details_table_extras'),
                        ].map((name, index) => (
                          <StyledTableCell key={index}>
                            {name}
                          </StyledTableCell>
                        ))
                      }
                    </StyledHeadTableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.items.map((item) => {
                        const height = parseInt(item.features?.find(({ code }) => code === 'MAG')?.keyed_value ?? '0')
                        const width = parseInt(item.features?.find(({ code }) => code === 'SZEL')?.keyed_value ?? '0')

                        return (
                          <StyledTableRow key={item.id}>
                            <StyledTableCell>{t(item.product?.description)}</StyledTableCell>
                            {
                              item.features
                              .filter(({ code }) => { return code == "PROD" || code == "ELZ" || code == "FENY" || code == "PANT" || code == "SZIN" })
                              .map(({ option_code, keyed_value }, index) => (
                                <StyledTableCell key={index}>
                                  {option_code} 
                                  {option_code == "RAL_NCS" ? " (" + (keyed_value ?? "") + ")" : ""}
                                </StyledTableCell>
                              ))
                            }
                            <StyledTableCell>{height} mm</StyledTableCell>
                            <StyledTableCell>{width} mm</StyledTableCell>
                            <StyledTableCell>{item.quantity}</StyledTableCell>
                            <StyledTableCell>{item.nettData?.Meret.toFixed(4)} m2</StyledTableCell>
                            <StyledTableCell>{(item.nettData?.Price)?.toLocaleString('hu-HU', {
                              style: 'currency',
                              currency: 'HUF',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}</StyledTableCell>
                            <StyledTableCell>{(item.nettData?.PriceBrutto)?.toLocaleString('hu-HU', {
                              style: 'currency',
                              currency: 'HUF',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBreakWord}>
                              {
                                item.nettData?.Extrak.map(({ name, value }) =>
                                  `${name}: ${value}`
                                ).join(', ')
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table> }
                <Box mt={5} />
                <Divider />
                <Box mt={5} />
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Typography className={classes.subTitle}>
                      {t('order_details_status')}
                    </Typography>
                    <Box mt={3} ml={3} mb={5}>
                      {data.status ? data.status.text : ""}
                    </Box>
                    <Box mt={5} />
                    <Typography className={classes.subTitle}>
                      {t('order_details_shipping_mode')}
                    </Typography>
                    <Box mt={3} ml={3} mb={5}>
                      {data.shipModeName}
                    </Box>
                    <Typography className={classes.subTitle}>
                      {t('order_details_comment_text')}
                    </Typography>
                    <Box mt={3} ml={3} mb={5}>
                      {t('order_details_table_self_identification')} {data.purchase_number ?? "-"}
                    </Box>
                    <Box mt={0} ml={3} mb={5}>
                      {data.note}
                    </Box>
                  </Grid>
                  <Grid item xs={6} container direction={'column'}>
                    <Typography className={classes.subTitle}>
                      {t('order_details_payment_data')}
                    </Typography>
                    <div className={classes.infoRow}>
                      <Typography variant="body1">{t('order_details_product_quantity_text')}</Typography>
                      <Typography variant="body1">{data.items.reduce((acc, item) =>
                        acc + item.quantity
                        , 0)} {t('order_details_count_text')}</Typography>
                    </div>
                    <div className={classes.infoRow}>
                      <Typography variant="body1">{t('order_details_product_size_of_all_text')}</Typography>
                      <Typography variant="body1">{
                        data.totalArea
                      } m2</Typography>
                    </div>
                    <div className={classes.infoRow}>
                      <Typography variant="body1">{t('order_details_net_text')}</Typography>
                      <Typography variant="body1">{data.totalNetPrice?.toLocaleString('hu-HU', {
                        style: 'currency',
                        currency: 'HUF',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}</Typography>
                    </div>
                    <div className={classes.infoRow}>
                      <Typography variant="body1">{t('order_details_vat_text')}</Typography>
                      <Typography variant="body1">{(data.totalBrutPrice - data.totalNetPrice)?.toLocaleString('hu-HU', {
                        style: 'currency',
                        currency: 'HUF',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}</Typography>
                    </div>
                    <div className={classes.infoRow} style={{ border: 'unset' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        {t('order_details_price_summary_text')}
                      </Typography>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        {data.totalBrutPrice.toLocaleString('hu-HU', {
                          style: 'currency',
                          currency: 'HUF',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ flexShrink: 1 }}>
                      {t('order_details_thankyou_message')}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <HideOnPrint component={Grid} item lg={3}>
              <Paper elevation={3} className={classes.rightSidebar}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  fullWidth
                  target={'_blank'}
                  href={`/print/${id}`}
                >
                  {t('order_details_print_button')}
                </Button>
              </Paper>
            </HideOnPrint>
          </Grid>
          : <>
          <Box mb={3}><Alert style={{justifyContent: "center"}} severity="info">{error}</Alert></Box>
          </>
      }
    </div>
  );
};

export default OrderDetails;
