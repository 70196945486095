import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { FC, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { productActions } from '../../data-management/productsSlice';
import { useProducts } from '../../hooks/useProducts';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { useProduct } from '../../hooks/useProduct';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type NoteModalProps = {
  open: boolean
  onClose: () => void
  productId: number
}

export const NoteModal: FC<NoteModalProps> = (
  {
    open,
    onClose,
    productId,
  },
) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [note, setNote] = useState('');

  const product = useProduct(productId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      setNote(product?.note ?? '');
    }
  }, [open, product]);

  const onSave = () => {
    dispatch(productActions.modifyProductNoteById({
      id: productId,
      note,
    }));
    onClose();
  };

  const onModalClose = () => {
    onClose();
  };

  const inputOnChange: StandardInputProps['onChange'] = (e) => {
    setNote(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onModalClose}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          {t('note_modal_comment_text')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          name={'note'}
          type={'string'}
          autoComplete={'off'}
          variant={'filled'}
          margin={'dense'}
          value={note}
          onChange={inputOnChange}
          rows={10}
          placeholder={t('note_modal_write_comment')}
          inputProps={{
            maxLength: 250,
          }}
        />
        <Typography>
          {note.length} / 250 {t('note_modal_characters_text')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSave}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          {t('note_modal_submit_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};