import React, { FC, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import TimesIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { flashActions } from '../../data-management/flashSlice';
import { useOrderItemDeleter } from '../../hooks/useOrderItemDeleter';
import {
  Badge,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { OrderItemExtrasModal } from './OrderItemExtrasModal';
import { useEditOrderItem } from '../../hooks/useEditOrderItem';
import { useToken } from '../../hooks/useToken';
import { useCategoryId } from '../../hooks/useCategoryId';
import { OrderItem as OrderItemType } from '../../data-management/orderSlice';
import { Block, Info } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(196, 196, 196, 0.1)',
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      border: 'none',
    },
  }),
)(TableCell);

type OrderItemProps = {
  index: number;
  item: OrderItemType;
  can_edit: number;
};

export const OrderItem: FC<OrderItemProps> = ({ item, index, can_edit }) => {

  const token = useToken();
  const dispatch = useDispatch();
  const { fields } = useCategoryId();
  const { t } = useTranslation();

  const deleteOrderItem = useOrderItemDeleter(token);

  const [extrasModal, setExtrasModal] = useState(false);
  const editOrderItem = useEditOrderItem();

  const onHandleDelete: (itemId: number) => void = (itemId) => {
    deleteOrderItem(itemId, index).then(() => {
      dispatch(flashActions.addFlashMessage(t('order_item_success_deleted')));
    });
  };

  const onHandleEdit: (itemId: number) => void = (itemId) => {
    editOrderItem(itemId).catch((e) => {
      console.log(e);
      dispatch(
        flashActions.addFlashMessage(
          t('order_item_error_on_moving_product'),
        ),
      );
    });
  };

  return (
    <StyledTableRow key={index}>
      <StyledTableCell component="th" scope="row">
        {index + 1}
      </StyledTableCell>
      {fields?.map(({ code }, index) => {
        switch (code) {
          case 'PROD':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.ProductCode}
              </StyledTableCell>
            );

          case 'SZIN':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.SzinErtek !== undefined
                  ? `${item.nettdata.Szin} ${item.nettdata.SzinErtek}`
                  : item.nettdata.Szin}
              </StyledTableCell>
            );

          case 'FENY':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Fenyesseg ?? ''}
              </StyledTableCell>
            );

          case 'VA':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Vastagsag ?? ''}
              </StyledTableCell>
            );

          case 'MAG':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Magassag} mm
              </StyledTableCell>
            );

          case 'SZEL':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Szelesseg} mm
              </StyledTableCell>
            );

          case 'QUANT':
            return (
              <StyledTableCell key={index} align="right">
                {parseInt(item.nettdata.Qty)}
              </StyledTableCell>
            );

          case 'PANT':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Pantolas}
              </StyledTableCell>
            );

          case 'ELZ':
            return (
              <StyledTableCell key={index} align="right">
                {item.nettdata.Elmegmunk}
              </StyledTableCell>
            );

          default:
            return <StyledTableCell key={index} align="right" />;
        }
      })}
      <StyledTableCell align="right">
        {parseFloat(item.nettdata.Meret).toFixed(2)} m2
      </StyledTableCell>
      <StyledTableCell align="right">
        {item.nettdata.SzallMunkanap} {t('order_item_day_text')}
      </StyledTableCell>
      <StyledTableCell align="right">
        {item.nettdata.Extrak?.length ? (
          <IconButton onClick={() => setExtrasModal(true)}>
            <Badge
              badgeContent={item.nettdata.Extrak?.length ?? 0}
              color="primary"
            >
              <CheckIcon />
            </Badge>
          </IconButton>
        ) : (
          <IconButton disabled>
            <TimesIcon />
          </IconButton>
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        {Math.round(
          Number(item.nettdata.Price) * Number(item.nettdata.Qty),
        ).toLocaleString('hu')}{' '}
        Ft
      </StyledTableCell>
      <StyledTableCell align="center">
        {item.note ? (
          <Tooltip title={item.note}>
            <Info />
          </Tooltip>
        ) : (
          <Block />
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        {can_edit ? (
          <EditIcon
            style={{ color: '#535353', cursor: 'pointer', marginRight: 10 }}
            onClick={() => onHandleEdit(item.id)}
          />
        ) : <></> }
        <DeleteIcon
          style={{ color: '#535353', cursor: 'pointer' }}
          onClick={() => onHandleDelete(item.id)}
        />
      </StyledTableCell>
      <OrderItemExtrasModal
        open={extrasModal}
        onClose={() => setExtrasModal(false)}
        extras={item?.nettdata?.Extrak ?? []}
      />
    </StyledTableRow>
  );
};
