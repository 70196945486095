import React, { FC, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDispatch } from 'react-redux';
import { productActions } from '../../data-management/productsSlice';
import { ImportProductXlsx } from './ImportProductXlsx';
import { useCategoryId } from '../../hooks/useCategoryId';
import { useProducts } from '../../hooks/useProducts';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { Delete } from '@material-ui/icons';
import { ProductItem } from './ProductItem';
import { useAddProduct } from '../../hooks/useAddProduct';
import { ProductListRemoveModal } from './ProductListRemoveModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    flexBasis: 0,
  },
  title: {
    fontWeight: 300,
    paddingBottom: theme.spacing(5),
    paddingLeft: '24px',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
  },
  addRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  addRowText: {
    color: '#B2A77D',
    textDecoration: 'underline',
    marginRight: 20,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    marginBottom: 0,
  },
  items: {
    '& > :nth-of-type(odd)': {
      backgroundColor: 'rgba(196, 196, 196, 0.1);',
      '&.notValid': {
        backgroundColor: 'rgba(196, 124, 124, 0.2);',
      },
    },
    '& > .notValid': {
      backgroundColor: 'rgba(196, 124, 124, 0.2);',
    },
  },
  orText: {
    marginRight: theme.spacing(4),
  },
  deleteActivesButton: {
    fontWeight: 700,
    marginTop: theme.spacing(0.5),
  },
}));

type ProductListProps = {}

export const ProductList: FC<ProductListProps> = React.memo(() => {

  const {
    catId,
    fields,
  } = useCategoryId();

  const classes = useStyles();

  const dispatch = useDispatch();
  const products = useProducts();
  const [removeActivesDialog, setRemoveActivesDialog] = useState(false);
  const onAddProduct = useAddProduct();

  const {
    allCheck,
    multipleActionRow,
    productIds,
  } = useMemo<{
    allCheck: boolean
    multipleActionRow: boolean
    productIds: number[]
  }>(() => {

    const allCheck = products.length > 0 ? products.every((product) => product.active) : false;
    const multipleActionRow = products?.some(({ active }) => active);

    const productIds = products.map(({ key_id }) => key_id);

    return ({
      allCheck,
      multipleActionRow,
      productIds,
    });
  }, [products]);

  useEffect(() => {
    if (!products || products.length === 0) {
      onAddProduct();
    }
  }, [products]);

  const {
    handleChecks,
    openRemoveActivesDialog,
    closeRemoveActivesDialog,
    successRemoveActivesDialog,
  } = useMemo<{
    handleChecks: SwitchBaseProps['onChange']
    openRemoveActivesDialog: () => void
    closeRemoveActivesDialog: () => void
    successRemoveActivesDialog: () => void
  }>(() => {

    const handleChecks: SwitchBaseProps['onChange'] = (e) => {
      if (e.target.checked) {
        dispatch(productActions.activateAll());
      } else {
        dispatch(productActions.deActivateAll());
      }
    };

    const openRemoveActivesDialog = () => {
      setRemoveActivesDialog(true);
    };

    const closeRemoveActivesDialog = () => {
      setRemoveActivesDialog(false);
    };

    const successRemoveActivesDialog = () => {
      closeRemoveActivesDialog();
      dispatch(productActions.removeActiveProducts());
    };

    return ({
      handleChecks,
      openRemoveActivesDialog,
      closeRemoveActivesDialog,
      successRemoveActivesDialog,
    });
  }, []);

  const renderedProductItems = useMemo(() =>
    productIds?.map((id, index) => (
      <ProductItem
        key={id}
        productId={id}
      />
    )), [productIds]);

    const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <Grid container>
          <Typography className={classes.title} variant="h4">
            {t('product_list_size_label')}
          </Typography>
          {
            multipleActionRow ?
              <Box ml={6}>
                <Button
                  variant={'text'}
                  color={'secondary'}
                  onClick={openRemoveActivesDialog}
                  startIcon={<Delete />}
                  className={classes.deleteActivesButton}
                >
                  {t('product_list_remove_selected_options')}
                </Button>
              </Box> : <></>
          }
        </Grid>
        <TableContainer component={Box}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox checked={allCheck} onChange={handleChecks} name="allCheck" />
                </TableCell>
                {fields.map(({ label }, index) => (
                  <TableCell key={index} align="left">{t(label)}</TableCell>
                ))}
                <TableCell align="left" />
                {
                  catId !== 4 ? (
                    <TableCell align="left" />
                  ) : <></>
                }
                <TableCell align="center" />
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody className={classes.items}>
              {renderedProductItems}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.addRow}>
          <Button
            className={classes.addRowText}
            onClick={() => onAddProduct()}
            startIcon={
              <AddCircleIcon color={'inherit'} />
            }
          >
            {t('product_list_add_product_row')}
          </Button>
          <Typography className={classes.orText}>
            {t('product_list_or_label')}
          </Typography>
          <ImportProductXlsx
            fields={fields}
            categoryId={catId ?? ''}
          />
        </div>
      </Container>
      <ProductListRemoveModal
        open={removeActivesDialog}
        onClose={closeRemoveActivesDialog}
        onSuccess={successRemoveActivesDialog}
      />
    </div>
  );
});
