import {
  Dialog,
  DialogContent,
  makeStyles,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type OrderItemExtrasModalProps = {
  open: boolean
  onClose: () => void
  extras: any[]
}

export const OrderItemExtrasModal: FC<OrderItemExtrasModalProps> = (
  {
    open,
    onClose,
    extras
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const onModalClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={onModalClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        square: true
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant={'h5'}>
          {t('order_item_extas_modal_title_text')}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onModalClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">{t('order_item_extas_modal_extra_name')}</TableCell>
                <TableCell align="left">{t('order_item_extas_modal_extra_price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extras.map(({name, value}, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" align="right">
                    {name}
                  </TableCell>
                  <TableCell align="left">
                    {value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onModalClose}
          color="secondary"
          variant={'contained'}
          size={'small'}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
