import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MainLayout } from './App/Layouts/MainLayout';
import { AuthLayout } from './App/Layouts/AuthLayout';
import Home from './App/Home';
import { Login } from './App/Auth/Login';
import Temp from './App/Components/Temp';
import { PageNotFound } from './App/404/PageNotFound';
import { persistor, store } from './App/data-management/store';
import { PrivateRoute } from './App/Components/Routes/PrivateRoute';
import { createMuiTheme, ThemeProvider, ThemeOptions } from '@material-ui/core/styles';
import { Order } from './App/Order/Order';
import { SizeGroupList } from './App/SizeGroups/SizeGroupList';
import { SizeGroupSingle } from './App/SizeGroups/SizeGroupSingle';
import { SettingListPage } from './App/Settings/SettingsListPage';
import { PrivacyPage } from './App/Settings/PrivacyPage';
import { StatisticPage } from './App/Settings/StatisticPage';
import { NewsletterPatternPage } from './App/Newsletter/NewsletterPatternPage';
import { EditNewsletterPatternPage } from './App/Newsletter/EditNewsletterPatternPage';
import { NewsletterGroupPage } from './App/Newsletter/NewsletterGroupPage';
import { NewsletterSendPage } from './App/Newsletter/NewsletterSendPage';
import { TermsPage } from './App/Settings/TermsPage';
import SettingItem from './App/Settings/Single';
import Register from './App/Auth/RegistrationPage';
import Privacy from './App/Auth/PrivacyPage';
import Contact from './App/Contact/ContactForm';
import ProfileEdit from './App/Profile/ProfileEdit';
import OrderHistoryList from './App/OrderHistory/List';
import OrderHistoryDetails from './App/OrderHistory/OrderDetails';
import './App.css';
import Downloads from './App/Downloads';
import { ForgottenPassword } from './App/Auth/ForgottenPassword';
import React, { FC } from 'react';
import { ResetPassword } from './App/Auth/ResetPassword';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { UserListPage } from './App/UserManagement/UserListPage';
import { UserShowPage } from './App/UserManagement/UserShowPage';
import { RoleListPage } from './App/UserManagement/RoleListPage';
import { PersistGate } from 'redux-persist/integration/react';
import { SyncPage } from './App/UserManagement/SyncPage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { huHU as coreHu } from '@material-ui/core/locale';
import { huHU } from './gridHuLocalization';
import { CronLog } from './App/CronLog/CronLog';
import PrintView from './App/Order/PrintView';
import SavedOrderPrintView from './App/SavedOrder/PrintView';
import SavedOrderList from './App/SavedOrder/List';
import { EditNewsletterGroupPage } from './App/Newsletter/EditNewsletterGroupPage';
import { useTranslation } from 'react-i18next';
import './i18n';

const focusedColor = '#fcba03';

const muiStyle: ThemeOptions = {
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#b81e22',
    },
    secondary: {
      main: '#B2A77D',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#ffffff',
        border: 0,
        borderRadius: 0,
        textTransform: 'unset',
        padding: '12px 20px',
        marginBottom: 15,
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 'calc(100% - 15px)',
          height: 'calc(100% - 15px)',
          transform: 'translate(-50%, -50%)',
        },
      },
      label: {
        lineHeight: 1.25,
      },
      containedPrimary: {
        backgroundColor: '#535353',
        color: '#ffffff',
        '&:after': {
          border: '1px solid rgba(229, 229, 229, 0.5)',
        },
        '&:hover': {
          backgroundColor: '#535353',
        },
      },
      containedSecondary: {
        backgroundColor: '#B2A77D',
        color: '#ffffff',
        '&:after': {
          border: '1px solid rgba(229, 229, 229, 0.5)',
        },
        '&:hover': {
          backgroundColor: '#B2A77D',
        },
      },
      contained: {
        '&:hover': {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#ffffff',
        },
      },
      containedSizeSmall: {
        padding: '12px 40px',
        fontSize: 14,
      },
      containedSizeLarge: {
        fontSize: 16,
      },
    },
    MuiInput: {
      underline: {
        '&:focus': {
          borderBottom: `2px solid ${focusedColor}`,
        },
        '&:after': {
          borderBottom: `2px solid ${focusedColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: focusedColor,
        },
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: '#ffffff',
      },
    },
  },
};

const themeHu = createMuiTheme(muiStyle, huHU, coreHu);
const theme = createMuiTheme(muiStyle);

const App: FC = () => {
  const { i18n } = useTranslation();

  return (
    <ThemeProvider theme={i18n.language === "hu" ? themeHu : theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <GoogleReCaptchaProvider
              reCaptchaKey="6LceLxAbAAAAAFCeT8xOghYkxc-wK53w8m_2upxJ"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                  <Switch>
                  <Route exact path={['/', '/regisztracio', '/elfelejtett-jelszo', '/jelszo-visszaallitas/:token', '/print/:id', '/order/saved/one/:id', '/adatkezeles']}>
                      <AuthLayout>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/regisztracio" component={Register} />
                        <Route exact path="/adatkezeles" component={Privacy} />
                        <Route exact path="/elfelejtett-jelszo" component={ForgottenPassword} />
                        <Route exact path="/jelszo-visszaallitas/:token" component={ResetPassword} />
                      </AuthLayout>
                      <Route exact path="/print/:id" component={PrintView} />
                      <Route exact path="/order/saved/one/:id" component={SavedOrderPrintView} />
                    </Route>
                    <PrivateRoute
                      path={[
                        '/dashboard',
                        '/order',
                        '/size-list',
                        '/size-list/create',
                        '/size-rule/:id/edit',
                        '/settings',
                        '/terms',
                        '/privacy',
                        '/statistic',
                        '/newsletter-patterns',
                        '/newsletter-groups',
                        '/newsletter-send',
                        '/settings/create',
                        '/temp',
                        '/kapcsolat',
                        '/profil',
                        '/order-history',
                        '/order-history/details',
                        '/saved-orders',
                        '/downloads',
                        '/users',
                        '/roles',
                        '/user/:userId',
                        '/sync',
                        '/cron-log'
                      ]}
                    >
                      <MainLayout>
                        <Route path="/dashboard" component={Home} />
                        <Route exact path="/order" component={Order} />
                        <Route exact path="/order/load/:id" component={Order} />
                        <Route exact path="/order/load-existing/:existing" component={Order} />
                        <Route exact path="/size-list" component={SizeGroupList} />
                        <Route exact path="/size-list/create" component={SizeGroupSingle} />
                        <Route exact path="/size-rule/:id/edit" component={SizeGroupSingle} />
                        <Route exact path="/settings" component={SettingListPage} />
                        <Route exact path="/terms" component={TermsPage} />
                        <Route exact path="/privacy" component={PrivacyPage} />
                        <Route exact path="/statistic" component={StatisticPage} />
                        <Route exact path="/newsletter-patterns" component={NewsletterPatternPage} />
                        <Route exact path="/newsletter-patterns/edit/:id" component={EditNewsletterPatternPage} />
                        <Route exact path="/newsletter-patterns/create" component={EditNewsletterPatternPage} />
                        <Route exact path="/newsletter-groups" component={NewsletterGroupPage} />
                        <Route exact path="/newsletter-groups/edit/:id" component={EditNewsletterGroupPage} />
                        <Route exact path="/newsletter-groups/create" component={EditNewsletterGroupPage} />
                        <Route exact path="/newsletter-send" component={NewsletterSendPage} />
                        <Route exact path="/settings/create" component={SettingItem} />
                        <Route path="/kapcsolat" component={Contact} />
                        <Route path="/profil" component={ProfileEdit} />
                        <Route exact path="/saved-orders" component={SavedOrderList} />
                        <Route exact path="/order-history" component={OrderHistoryList} />
                        <Route exact path="/order-history/details/:id" component={OrderHistoryDetails} />
                        <Route path="/downloads" component={Downloads} />
                        <Route path="/temp" component={Temp} />
                        <Route path="/users" component={UserListPage} />
                        <Route path="/user/:userId" component={UserShowPage} />
                        <Route path="/roles" component={RoleListPage} />
                        <Route path="/sync" component={SyncPage} />
                        <Route path="/cron-log" component={CronLog} />
                      </MainLayout>
                    </PrivateRoute>
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                </Router>
              </MuiPickersUtilsProvider>
            </GoogleReCaptchaProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
  );
};

export default App;
