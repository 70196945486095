import { useCallback } from 'react';
import { useToken } from './useToken';
import { Product } from '../data-management/productsSlice';
import axios, { AxiosResponse } from 'axios';
import { OrderItem } from '../data-management/orderSlice';
import { useSelectedOrganisationId } from './useSelectedOrganisationId';
import { useCategoryId } from './useCategoryId';
import { useTranslation } from 'react-i18next';

type UseOrderItemAdderHookType = () => (
  products: Product[]
) => Promise<AxiosResponse<OrderItem[]>>

export const useOrderItemAdder: UseOrderItemAdderHookType = () => {

  const token = useToken()
  const orgId = useSelectedOrganisationId()
  const { catId } = useCategoryId();
  const { t } = useTranslation();

  return useCallback(async (products) => {
    products.forEach(product => {
      if (!product.product_id || !product.quantity)
        throw new Error(t('use_order_item_adder_error_message'));
    });

    return await axios
      .post<OrderItem[]>(
        `${process.env.REACT_APP_SERVER_URL}/order/item`,
        {
          items: products,
          org_id: orgId,
          category_id: catId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
  }, [token, orgId])
}
