import React, { FC } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { Block, CloudOff, Done, SettingsOverscan } from '@material-ui/icons';
import { ValidationState } from '../../data-management/productsSlice';
import { useProduct } from '../../hooks/useProduct';
import { ProductValidation } from './ProductValidation';
import { useTranslation } from 'react-i18next';

type ValidationStateMap = {
  tooltip: string
  icon: JSX.Element
}

const validationMap: {
  [name in (ValidationState | 'badSize')]: ValidationStateMap
} = {
  'waiting': {
    tooltip: 'product_validator_alert_waiting_for_validation',
    icon: (
      <CircularProgress
        size={20}
        color={'secondary'}
      />
    ),
  },
  'badSize': {
    tooltip: 'product_validator_alert_size_error',
    icon: (
      <SettingsOverscan
        htmlColor={'red'}
      />
    ),
  },
  'incomplete': {
    tooltip: 'product_validator_alert_missing_product',
    icon: <CloudOff />,
  },
  'processing': {
    tooltip: 'product_validator_alert_validation_in_progress',
    icon: (
      <CircularProgress
        size={20}
        color={'primary'}
      />
    ),
  },
  'failed': {
    tooltip: 'product_validator_alert_failed_validation',
    icon: <Block
      htmlColor={'red'}
    />,
  },
  'correct': {
    tooltip: 'product_validator_alert_product_is_good',
    icon: <Done
      htmlColor={'green'}
    />,
  },
};

type ProductValidatorProps = {
  productId: number
}

export const ProductValidator: FC<ProductValidatorProps> = React.memo((
  {
    productId,
  },
) => {

  const { t } = useTranslation();
  const product = useProduct(productId);
  const sizeValidationState = product.sizeValidationState
  const errorText = (product.error?.text.substring(product.error?.text.indexOf('//') + 2, product.error?.text.split('/', 3).join('/').length)) ?? t('product_validator_alert_no_reason');
  const failed = product.validationState === 'failed'
  const badSize = sizeValidationState === 'bad'

  const validationState = validationMap[badSize ? 'badSize' : product.validationState];

  const tooltipText = failed ? `${t(validationState.tooltip)}: \n${t(errorText ?? "")}` : t(validationState.tooltip)

  return (
    <>
      <Tooltip title={tooltipText}>
        {validationState.icon}
      </Tooltip>
      <ProductValidation product={product} />
    </>
  );
});