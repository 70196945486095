import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Paper, Typography, Button, Box, CircularProgress } from '@material-ui/core';
import { DataGrid, GridColumns, GridSortModel, GridFilterModel, GridToolbar } from '@material-ui/data-grid';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { parseISO } from 'date-fns';
import { SavedOrder } from '../hooks/useSaveProducts';
import { Product } from '../data-management/productsSlice';
import axios from 'axios';
import { useToken } from '../hooks/useToken';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { useIsListTypeOrganization } from '../hooks/useIsListTypeOrganization';
import { useIsZeroTypeOrganization } from '../hooks/useIsZeroTypeOrganization';
import { ConfirmSavedOrderDeleteModal } from '../Components/Order/ConfirmSavedOrderDeleteModal';
import { ConfirmLoadOrderModal } from '../Components/Order/ConfirmLoadOrderModal';
import { AskPricePrintModal } from '../Components/Order/AskPricePrintModal';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    minHeight: '90vh',
    '& .MuiDataGrid-row.Mui-even': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
    },
  },
  paper: {
    padding: '40px 50px 52px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  filterItem: {
    flexGrow: 1,
    marginRight: theme.spacing(20),
  },
  itemFlexGrow: {
    flexGrow: 1,
    minHeight: 400,
    padding: '50px',
  },
  statusRow: {
    backgroundColor: '#DEFFBD',
    paddingLeft: '6px',
    paddingRight: '6px'
  }
}));

const SavedOrderList = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const token = useToken();
  const orgId = useSelectedOrganisationId();
  const browserHistory = useHistory();

  const [items, setItems] = useState<SavedOrder[] | undefined>(undefined);
  const [deleteSavedOrderModal, setDeleteSavedOrderModal] = useState<any | undefined>(undefined);
  const [loadOrderModal, setLoadOrderModal] = useState<any | undefined>(undefined);
  const [askPriceModal, setAskPriceModal] = useState<any | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  
  const isListType = useIsListTypeOrganization();
  const isZeroType = useIsZeroTypeOrganization();
  const price_option_from_arkat = isZeroType ? "my_price" : "list_price";

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({items: [{}]});

  const refreshTable = function(){
    if(orgId != -1){
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/order/saved/all/${orgId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setItems(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }

  useEffect(() => {
    refreshTable();
  }, [orgId]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: t('saved_order_list_column_id'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 0.40
    },
    {
      field: 'created_at',
      headerName: t('saved_order_list_column_created_at'),
      flex: 0.60,
      valueGetter: (params) => {
        return typeof params.value === 'string' ?
          parseISO(params.value) : new Date();
      },
      type: 'dateTime',
    },
    {
      field: 'name',
      headerName: t('saved_order_list_column_name'),
      flex: 0.75,
      type: 'dateTime',
    },
    {
      field: 'length',
      headerName: t('saved_order_list_column_pos_number'),
      flex: 0.5,
      type: 'number',
      valueGetter: (params) => {
        return params.getValue(params.id, 'items') ? (params.getValue(params.id, 'items') as Product[]).filter((prod_piece: any) => { return !prod_piece.validationState || prod_piece.validationState != "incomplete" }).length : 0;
      },
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'show',
      headerName: '',
      sortable: false,
      resizable: false,
      editable: false,
      hideSortIcons: true,
      filterable: false,
      flex: 0.75,
      disableColumnMenu: true,
      renderHeader: () => (
        <div />
      ),
      renderCell: (params) => (
        <div className={classes.wrapper}>
          <Button style={{margin: "5px"}} onClick={() => {
              setLoadOrderModal(params.row.id);
            }}>
            <EditIcon style={{ fill: '#535353' }}></EditIcon>
            <Typography style={{color: "#535353", marginLeft: "4px"}}>
              {t('saved_order_list_load_button')}
            </Typography>
          </Button>
          <Button style={{margin: "5px"}} onClick={() => {
              isListType ? window.open(`/order/saved/one/${params.row.secure_id}?price_option=${price_option_from_arkat}&percentage=0`, '_blank', 'noopener,noreferrer') : setAskPriceModal(params.row.secure_id);
            }}>
            <PrintIcon style={{ fill: '#535353' }}></PrintIcon>
            <Typography style={{color: "#535353", marginLeft: "4px"}}>
              {t('saved_order_list_print_button')}
            </Typography>
          </Button>
          <Button style={{margin: "5px"}} onClick={() => {
              setDeleteSavedOrderModal({
                id: params.row.id,
                name: params.row.name,
                created_at: params.row.created_at,
              });
            }}>
            <DeleteIcon style={{ fill: '#B81E22' }}></DeleteIcon>
            <Typography style={{color: "#B81E22", marginLeft: "4px"}}>
              {t('saved_order_list_delete_button')}
            </Typography>
          </Button>
        </div>
      ),
      align: 'left',
    },
  ];

  const rows = items ?? [];

  return (
    <>
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.filterItem} variant="h4">
              {t('saved_order_list_title_text')}
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item className={classes.itemFlexGrow}>
        {orgId === -1 ? <Box mb={3}><Alert severity="warning">{t('saved_order_list_choose_company')}</Alert></Box> : <></>}
        <Paper>
        {loading ? <Grid container justify={'center'}><Box p={2}><CircularProgress/></Box></Grid> :
          <div style={{ minHeight: 300, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
              sortModel={sortModel}
              onSortModelChange={({ sortModel }) => setSortModel(sortModel)}
              filterModel={filterModel}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div> }
        </Paper>
      </Grid>
    </Grid>
    <ConfirmSavedOrderDeleteModal
        open={!!deleteSavedOrderModal}
        onClose={() => {
          setDeleteSavedOrderModal(undefined);
        }}
        onSuccess={() => {
          refreshTable();
        }}
        id={deleteSavedOrderModal?.id}
        name={deleteSavedOrderModal?.name}
        createdAt={deleteSavedOrderModal?.created_at}
      />
    <ConfirmLoadOrderModal
        open={!!loadOrderModal}
        onClose={() => {
          setLoadOrderModal(undefined);
        }}
        onSuccess={() => {
          browserHistory.push(`/order/load/${loadOrderModal}`);
        }}
      />
    <AskPricePrintModal
        open={!!askPriceModal}
        url={`/order/saved/one/${askPriceModal}`}
        onClose={() => {
          setAskPriceModal(undefined);
        }}
      />
    </>
  );
};

export default SavedOrderList;
