import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useEditOrderItem } from './useEditOrderItem';
import { flashActions } from '../data-management/flashSlice';
import axios from 'axios';
import { useToken } from './useToken';
import { Product } from '../data-management/productsSlice';
import { useSelectedOrganisationId } from '../hooks/useSelectedOrganisationId';
import { UseEditOrderItemWithoutProds } from './useEditOrderItemWithoutProds';
import { useTranslation } from 'react-i18next';

type UseSaveProductsHookType = () => (saveName: string, catId: number, shipId: string | number, shipMode: string | number, note: string, purchaseNumber: string) => Promise<void>

export type SavedOrder = {
  id: string | number
  name: string
  items: Product[]
  category: {
    id: string | number
    name: string
  }
  created_at: string
}

export const useSaveProducts: UseSaveProductsHookType = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editOrderItem = UseEditOrderItemWithoutProds();
  const orgId = useSelectedOrganisationId();
  const token = useToken();

  return useCallback(async (saveName, catId, shipId, shipMode, note, purchaseNumber) => {
    try {
      const products = await editOrderItem();

      const data = {
        name: saveName,
        items: products,
        category_id: catId,
        organization_id: orgId,
        ship_id: shipId,
        ship_mode: shipMode,
        note: note,
        purchase_number: purchaseNumber,
      };

      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/order/saved`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    } catch (err) {
      console.log(err)
      dispatch(flashActions.addFlashMessage(t('use_save_products_error_message')));
    }
  }, [editOrderItem, dispatch]);
};