import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useSaveProducts } from '../../hooks/useSaveProducts';
import { useCategoryId } from '../../hooks/useCategoryId';
import { useTranslation } from 'react-i18next';

type SaveOrderDialogProps = {
  open: boolean
  onClose: () => void,
  shipId: string | number,
  shipMode: string | number,
  note: string,
  purchaseNumber: string,
}

export const SaveOrderDialog: FC<SaveOrderDialogProps> = (
  {
    open,
    onClose,
    shipId,
    shipMode,
    note,
    purchaseNumber
  }
) => {

  const [saveName, setSaveName] = useState('')
  const [disabled, setDisabled] = useState(false)
  const {catId} = useCategoryId()
  const { t } = useTranslation();

  const handleClose = () => {
    onClose()
  }

  const saveProducts = useSaveProducts();

  const onSaveItems = () => {
    setDisabled(true);
    saveProducts(saveName, Number(catId) ?? 0, shipId, shipMode, note, purchaseNumber)
      .then(() => {
        setDisabled(false);
        onClose()
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('save_order_dialog_title_text')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('save_order_dialog_save_description')}
          <TextField
            style={{ marginTop: '20px' }}
            variant="outlined"
            fullWidth
            value={saveName}
            onChange={(e) => setSaveName(e.target.value)}
            placeholder={t('save_order_dialog_save_name_placeholder')}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('save_order_dialog_cancel_button')}
        </Button>
        <Button disabled={disabled} onClick={() => onSaveItems()} color="secondary" autoFocus>
          {t('save_order_dialog_submit_button')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
