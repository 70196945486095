import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ProductListRemoveModalProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ProductListRemoveModal: FC<ProductListRemoveModalProps> = (
  {
    open,
    onClose,
    onSuccess
  }
) => {

  const { t } = useTranslation();

  return (
    <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>{t('product_list_remove_modal_confirm_delete')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {t('product_list_remove_modal_not_revertable')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t('product_list_remove_modal_no_button')}
      </Button>
      <Button onClick={onSuccess} color="secondary" autoFocus>
        {t('product_list_remove_modal_yes_button')}
      </Button>
    </DialogActions>
  </Dialog>
  );
};