import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBarImage from '../../../images/app-bar-image.png';
import TitleWrapper from '../../../images/title-wrapper.png';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { AdminOrgPicker } from './AdminOrgPicker';
import { LanguageSwitcher } from './LanguageSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: `url(${AppBarImage})`,
    backgroundRepeat: 'no-repeate',
    backgroundSize: 'cover',
    paddingLeft: theme.spacing(5),
    height: '116px',
  },
  titleWrapper: {
    position: 'absolute',
    top: '50%',
    left: 40,
    transform: 'translateY(-50%)',
    transition: 'left .3s',
    height: 70,
    width: 70,
  },
  titleWrapperLeft: {
    left: '340px',
  },
  title: {
    position: 'absolute',
    left: 65,
    top: 40,
    fontWeight: 300,
    fontSize: '36px',
    color: '#535353',
    transition: 'left .3s',
  },
  titleLeft: {
    left: '380px',
  },
  menu: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 25,
    height: 25,
    padding: theme.spacing(1),
    background: '#F6F7FA',
    boxShadow: '0px 1px 8px rgba(178, 167, 125, 0.6)',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#b2a77d',
      '& svg, path': {
        color: '#fff',
      },
    },
  },
}));

type CustomAppBarProps = {
  title: string
  toggleMenu: () => void
  open: boolean
}

export const CustomAppBar: FC<CustomAppBarProps> = ({ title, toggleMenu, open }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src={TitleWrapper}
        className={clsx(classes.titleWrapper, open ? classes.titleWrapperLeft : '')}
        alt="Nettfront"
      />
      <div className={clsx(classes.title, open ? classes.titleLeft : '')}>{title}</div>
      <AdminOrgPicker open={open} />
      <LanguageSwitcher />
      <div className={classes.menu} onClick={toggleMenu}>
        <MenuIcon style={{ color: '#b2a77d', fontSize: '25px' }} />
      </div>
    </div>
  );
};

