import React, { createRef, FC, useState } from 'react';
import { Box, Button, Grid, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { UniqueProductConfirmModal } from './UniqueProductConfirmModal';
import { Delete, Visibility } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { UniqueProduct, uniqueProductsActions } from '../../data-management/uniqueProductsSlice';
import { RootState } from '../../data-management/store';
import Dropzone, { DropzoneOptions, DropzoneRef } from 'react-dropzone';
import { UniqueProductFileUploadModal } from './UniqueProductFileUploadModal';
import { UniqueProductFileShowModal } from './UniqueProductFileShowModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    width: '100%',
  },
  box: {
    width: '100%',
  },
  title: {
    fontWeight: 300,
    paddingBottom: theme.spacing(5),
  },
  grayBackground: {
    backgroundColor: 'rgba(196, 196, 196, 0.1)',
  },
  addRowText: {
    color: '#B2A77D',
    textDecoration: 'underline',
    marginRight: 20,
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    marginBottom: 0,
  },
  dropzone: {
    flex: 1,
    display: 'inline-flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '0 40px',
    borderRadius: 2,
    backgroundColor: 'rgba(229, 229, 229, 0.5);',
    border: '2px dashed #221E1F',
    color: '##221e1f',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    // margin: '0 20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const UniqueProductWrapper: FC = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const products = useSelector<RootState, UniqueProduct[]>(state => state.uniqueProducts.uniqueProducts);
  const description = useSelector<RootState, String>(state => state.uniqueProducts.description);
  const purchasingNumber = useSelector<RootState, String>(state => state.uniqueProducts.purchasingNumber);

  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [productIndexUpload, setProductIndexUpload] = useState<number | undefined>(undefined);
  const dropzoneRef = createRef<DropzoneRef>();

  const [showProductFilesIndex, setShowProductFilesIndex] = useState(-1);

  const openConfirmModal = () => {
    setConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const onAddProduct = () => {
    dispatch(uniqueProductsActions.addProduct());
  };

  const closeFileUploadModal = () => {
    setFileUploadModal(false);
    setProductIndexUpload(undefined);
  };

  const onFilesDrop: (index: number) => DropzoneOptions['onDrop'] =
    (index) => (acceptedFiles) => {
      setFileUploadModal(true);
      setSelectedFiles(acceptedFiles);
      setProductIndexUpload(index);
    };

  const openDropzoneUploadDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const onCloseProductFileShowModal = () => {
    setShowProductFilesIndex(-1);
  };

  return (
    <>
      <Paper
        square
        className={classes.paper}
        style={{padding: "24px 40px"}}
      >
        <Typography variant={'h4'} className={classes.title}>
          {t('unique_product_wrapper_informations')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              {t('unique_product_wrapper_project_description')}
            </Typography>
            <TextField
              value={description}
              multiline
              variant={'outlined'}
              placeholder={t('unique_product_wrapper_project_description_placeholder')}
              fullWidth
              rows={6}
              onChange={(e) => {
                dispatch(uniqueProductsActions.modifyDescription(e.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
            {t('unique_product_wrapper_self_identification')}
            </Typography>
            <TextField value={purchasingNumber} fullWidth onChange={(e) => {
                dispatch(uniqueProductsActions.modifyPurchasingNumber(e.target.value));
              }}/>
          </Grid>
        </Grid>
        <Box mt={4} />
        <Typography>
        {t('unique_product_wrapper_door_properties')}
        </Typography>
        <Box mt={2} />
        {
          products.map(({ description, files }, index) => (
            <Grid
              container
              className={classes.grayBackground}
              spacing={3}
              alignItems={'center'}
              key={index}
            >
              <Grid item xs={12} md={6} container justify={'flex-end'} alignItems={'center'}>
                <Grid item xs={12} md={10}>
                  <TextField
                    multiline
                    fullWidth
                    variant={'outlined'}
                    value={description}
                    onChange={(e) => {
                      dispatch(uniqueProductsActions.modifyProductDescription({
                        index,
                        description: e.target.value,
                      }));
                    }}
                    rows={4}
                    placeholder={t('unique_product_wrapper_door_description_placeholder')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container alignItems={'center'}>
                <Grid item alignItems={'center'} style={{flexGrow: 1}}>
                  <Dropzone
                    ref={dropzoneRef}
                    multiple
                    onDrop={onFilesDrop(index)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className={classes.dropzone} {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>
                            {t('unique_product_wrapper_attach_document')}
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
                <Grid item xs={3} container alignItems={'center'} justify={'center'}>
                  <Box mt={1.5}>
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={openDropzoneUploadDialog}
                    >
                      {t('unique_product_wrapper_upload')}
                    </Button>
                  </Box>
                  {
                    files.length > 0 ?
                      <Box>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          startIcon={<Visibility />}
                          onClick={() => {
                            setShowProductFilesIndex(index);
                          }}
                        >
                          {files.length} {t('unique_product_wrapper_file_numbers_uploaded')}
                        </Button>
                      </Box> : <></>
                  }

                </Grid>
                <Grid item xs={1} container alignItems={'center'}>
                  <Box>
                    <IconButton
                      onClick={() => {
                        dispatch(uniqueProductsActions.removeProduct(index));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))
        }
        <Box mt={4} />
        <Grid container justify={'center'}>
          <Button
            className={classes.addRowText}
            onClick={onAddProduct}
            startIcon={
              <AddCircleIcon color={'inherit'} />
            }
          >
            {t('unique_product_wrapper_new_product_row')}
          </Button>
        </Grid>
      </Paper>
      <Box mt={6} className={classes.box} />
      <Grid container justify={'flex-end'}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={openConfirmModal}
        >
          {t('unique_product_wrapper_require_custom_offer')}
        </Button>
      </Grid>
      <Box mb={10} className={classes.box} />
      <UniqueProductConfirmModal
        open={confirmModal}
        onClose={closeConfirmModal}
      />
      <UniqueProductFileUploadModal
        open={fileUploadModal}
        onClose={closeFileUploadModal}
        files={selectedFiles}
        productIndex={productIndexUpload}
      />
      <UniqueProductFileShowModal
        open={showProductFilesIndex !== -1}
        onClose={onCloseProductFileShowModal}
        productIndex={showProductFilesIndex}
      />
    </>
  );
};
